import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import CalendarIcon from "../images/calendar.svg"
import moment from 'moment'

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allStrapiArticle.edges
    return (
      <Layout>
        <div className="container px-4 py-5 pt-0" id="custom-cards">
          <h3 className="pb-2 text-center border-bottom mt-5">Latest articles</h3>
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            {posts.map(article => (
              <div className="col" key={article.node.id}>
                <Link to={`article/${article.node.Slug}`}>
                  <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: `url(${article.node.Cover.formats.medium.url})` }}>
                    <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                      <h2 className="pt-5 mt-5 mb-4 lh-1 fw-bold">{article.node.Title}</h2>
                      <p className="text-white text-shadow-1">{article.node.Preview}</p>
                      <ul className="d-flex list-unstyled mt-auto">
                        <li className="me-auto">
                          <StaticImage src="../images/codingsamples-dark.png" alt="Bootstrap" width={32} className="rounded-circle border border-white" />
                        </li>
                        <li className="d-flex align-items-center">
                          <CalendarIcon className="me-2" />
                          <small>{moment(article.node.Creation).format('LL')}</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allStrapiArticle(
      sort: { fields: Creation, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          Title
          Preview
          Creation
          Slug
          Cover {
            formats {
              medium {
                url
              }
            }
          }
        }
      }
    }
  }
`